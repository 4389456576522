import React, { useRef } from "react";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, IconButton, InputAdornment, List, Paper, Slide, Stack, styled, Tab, Tabs, TextField, Typography } from "@mui/material";
import { EntityPageLayout, PageLayout } from "components/Layout";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { InterventionListItem } from "../components/InterventionListItem";
import AddIcon from "@mui/icons-material/Add";
import { TransitionProps } from "@mui/material/transitions";
import { NewInterventionPage } from "./NewInterventionPage/NewInterventionPage";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";

const SearchTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    }
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const InterventionsPage: React.FC = () => {

  const dialogRef = useRef<HTMLDivElement>(null);
  const [isNewDialogOpen, setIsNewDialogOpen] = React.useState(false);
  const [isQuitDialogOpen, setIsQuitDialogOpen] = React.useState(false);

  const handleClickCreateButton = () => {
    setIsNewDialogOpen(true);
  };

  const handleNewDialogClose = () => {
    setIsQuitDialogOpen(true);
  };

  const handleQuitDialogQuit = () => {
    setIsNewDialogOpen(false);
    setIsQuitDialogOpen(false);
  };

  const handleQuitDialogStay = () => {
    setIsQuitDialogOpen(false);
  };

  const handleOnSave = () => {
    setIsNewDialogOpen(false);
  };

  return (
    <PageLayout>
      <Container sx={{py:2}}>
        <Stack direction='row' alignItems='center' gap={1}>
          <QuestionAnswerIcon color='primary'/>
          <Typography variant="h5" component="h1" fontWeight={700}>Interventions</Typography>
        </Stack>
      </Container>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Container disableGutters sx={{px:1}}>
          <Tabs value={0}>
            <Tab label="Personnel" />
            <Tab label="Équipe" />
            <Tab label="Toutes" />
          </Tabs>
        </Container>
      </Box>
      <Container disableGutters sx={{py:2, px:1, pb: 8}}>
        <Paper>
          <SearchTextField
            fullWidth 
            placeholder="Rechercher..." 
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Stack direction='row' justifyContent="space-between" sx={{flexGrow: 1, bgcolor: "grey.50", px:1}}>
            <Stack direction='row' alignItems='center'>
              <Typography variant='subtitle2' sx={{textTransform:"uppercase"}}>Nom</Typography>
              <IconButton size='small'><ArrowDropUpIcon /></IconButton>
              <IconButton size='small'><ArrowDropDownIcon /></IconButton>
            </Stack>
            <Stack direction='row' alignItems='center'>
              <Typography variant='subtitle2' sx={{textTransform:"uppercase"}}>Date</Typography>
              <IconButton size='small'><ArrowDropUpIcon /></IconButton>
              <IconButton size='small'><ArrowDropDownIcon /></IconButton>
            </Stack>
          </Stack>
          <List disablePadding>
            <InterventionListItem
              indiviualsName='Jackson Tucker'
              streetWorkName='Michael Baron'
              streetWorkTeamName='Travail de rue'
              date='2021-12-01'
              interventionType='Individuelle'
            />
            <Divider component="li" />
            <InterventionListItem
              indiviualsName='Michael Rowan'
              streetWorkName='Michael Baron'
              streetWorkTeamName='Travail de rue'
              date='2021-12-01'
              interventionType='Individuelle'
            />
            <Divider component="li" />
            <InterventionListItem
              indiviualsName='Allison R. Bland'
              streetWorkName='Michael Baron'
              streetWorkTeamName='Travail de rue'
              date='2021-12-01'
              interventionType='Routine'
            />
            <Divider component="li" />
            <InterventionListItem
              indiviualsName='Groupe de 21'
              streetWorkName='Michael Baron'
              streetWorkTeamName='Travail de rue'
              date='2021-12-01'
              interventionType='Présentation'
            />
            <Divider component="li" />
            <InterventionListItem
              indiviualsName='Russell Vargas'
              streetWorkName='Michael Baron'
              streetWorkTeamName='Travail de rue'
              date='2021-12-01'
              interventionType='Routine'
            />
          </List>
        </Paper>
      </Container>
      <Fab 
        variant="extended" 
        color="primary" 
        sx={{
          position: "fixed",
          pr:3,
          bottom: (theme) => theme.spacing(1),
          right: (theme) => theme.spacing(1),
        }}
        onClick={handleClickCreateButton}
      >
        <AddIcon sx={{ mr: 1 }} />
        Ajouter
      </Fab>
      <Dialog
        fullScreen
        open={isNewDialogOpen}
        onClose={handleNewDialogClose}
        TransitionComponent={Transition}
      >
        <DialogContent 
          sx={{p:0}}
          ref={dialogRef}>
          <EntityPageLayout
            leftIcon={<CloseIcon />}
            rightIcon={<SaveIcon />}
            leftIconOnClick={handleNewDialogClose}
            title="Nouvel intervention"
          >
            <NewInterventionPage onSave={handleOnSave} dialogRef={dialogRef}/>
          </EntityPageLayout>
        </DialogContent>
      </Dialog>
      <Dialog
        disableEscapeKeyDown
        open={isQuitDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Êtes-vous certain de vouloir quitter?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si vous quitter, vous allez perde tous les changements non enregistré.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleQuitDialogQuit}>Quitter</Button>
          <Button onClick={handleQuitDialogStay} autoFocus>
            Rester
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};