import React from "react";
import { Container, Paper, Typography, Divider, Stack, IconButton, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { DistributionValue } from "types";
import { AddDistributionDialog } from "modules/distribution/views/AddDistributionDialog";
import DeleteIcon from "@mui/icons-material/Delete";

export const NewInterventionSection4: React.FC = () => {

  const [distribution, setDistribution] = React.useState<DistributionValue>();
  const [distributions, setDistributions] = React.useState<DistributionValue[]>([]);
  const [isPickDistributionDialogOpen, setIsPickDistributionDialogOpen] = React.useState(false);

  const handlePickDistributionDialogClose = () => {
    setIsPickDistributionDialogOpen(false);
    setDistribution(undefined);
  };

  const handlePickDistributionSave = (value: DistributionValue) => {
    setDistributions([...distributions, value]);
    setIsPickDistributionDialogOpen(false);
  };

  const handleRemoveDistribution = (toRemove: DistributionValue) => {
    setDistributions(distributions.filter(x => x.id !== toRemove.id));
  };

  const handleOpenDistribution = (toOpen: DistributionValue) => {
    setDistribution(toOpen);
    setIsPickDistributionDialogOpen(true);
  };

  return (
    <Container disableGutters sx={{py:2, px:1}}>
      <Stack gap={2}>
        <Paper>
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography variant="h6" component="h2" fontWeight={700}>Distributions</Typography>
            <IconButton aria-label="Add" onClick={()=>setIsPickDistributionDialogOpen(true)}> 
              <AddIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Box paddingY={1}>
            {distributions.length > 0 ? (
              distributions.map((distribution) => (
                <ListItem key={distribution.id} disablePadding secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={()=>handleRemoveDistribution(distribution)}>
                    <DeleteIcon />
                  </IconButton>
                }>
                  <ListItemButton disableGutters sx={{p:0, pl:1}} onClick={()=>handleOpenDistribution(distribution)}>
                    <ListItemText
                      primary={distribution.name}
                      secondary={`${distribution.unit}: ${distribution.quantity}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ):(
              <Typography sx={{px:1}}>Aucune distribution</Typography>
            )}
          </Box>
          <AddDistributionDialog 
            isDialogOpen={isPickDistributionDialogOpen} 
            handleDialogClose={handlePickDistributionDialogClose}
            distribution={distribution}
            onSave={handlePickDistributionSave}/>
        </Paper>
      </Stack>
    </Container>
  );
};
