import { LocalizationProvider } from "@mui/lab";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { SettingsPage } from "modules/application/views/SettingsPage";
import { InterventionsPage } from "modules/interventions/views/InterventionsPage";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { createAppTheme } from "theme";
import DateAdapter from "@mui/lab/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/fr-ca";

dayjs.locale("fr-ca");

export const App: React.FC = () => {
  
  const theme = createTheme(createAppTheme("light"));

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter} locale={"fr-ca"}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<InterventionsPage />} />
          <Route path="settings" element={<SettingsPage />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
