import React from "react";
import { ListItemButton, Paper, Radio, Stack, Typography } from "@mui/material";

export interface InterventionTypeCardProps {
  value: string,
  title: string,
  description: string,
  isSelected: boolean,
  onClick: (value: string) => void;
}

export const InterventionTypeCard: React.FC<InterventionTypeCardProps> = (props: InterventionTypeCardProps) => {

  const handleOnClick = () => {
    props.onClick(props.value);
  };
  
  return (
    <Paper>
      <ListItemButton disableGutters onClick={handleOnClick} selected={props.isSelected}>
        <Stack direction="row" alignItems="start">
          <Radio value={props.value} checked={props.isSelected} />
          <Stack sx={{pr:1}}>
            <Typography variant="h6">{props.title}</Typography>
            <Typography>{props.description}</Typography>
          </Stack>
        </Stack>
      </ListItemButton>
    </Paper>
  );
};