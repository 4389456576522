import React from "react";
import { Container, Paper, Typography, Divider, Stack, IconButton, List, ListItem, ListItemButton, ListItemText, Slide, Dialog } from "@mui/material";
import { Individual, UnknownIndividual } from "types";
import AddIcon from "@mui/icons-material/Add";
import { IndividualPickerDialog } from "components/IndividualPicker";
import DeleteIcon from "@mui/icons-material/Delete";
import sortBy from "lodash/sortBy";
import faker  from "faker";
import dayjs from "dayjs";
import differenceBy from "lodash/differenceBy";
import { TransitionProps } from "@mui/material/transitions";
import { IndividualPage } from "modules/individual/views/IndividualPage";
import { NewUnknownIndividualDialog } from "modules/individual/views/NewUnknownIndividualDialog";
import isEqual from "lodash/isEqual";

const createIndividual = (index: number):Individual => {
  const birthdate = faker.date.between("1970-01-01", "2003-01-01");

  return {
    id: index,
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    surname: faker.commerce.productName().split(" ")[0],
    birthdate: birthdate,
    age: dayjs().diff(birthdate, "years")
  };
};

const individuals: Individual[] = [
  createIndividual(1),
  createIndividual(2),
  createIndividual(3),
  createIndividual(4),
  createIndividual(5),
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NewInterventionSection2: React.FC = () => {

  const [selectedIndividuals, setSelectedIndividuals] = React.useState<Individual[]>([]);
  const [selectedUnknownIndividuals, setSelectedUnknownIndividuals] = React.useState<UnknownIndividual[]>([]);
  const [selectedUnknownIndividual, setSelectedUnknownIndividual] = React.useState<UnknownIndividual>();

  const [isSelectIndividualDialogOpen, setIsSelectIndividualDialogOpen] = React.useState(false);
  const [isIndividualDialogOpen, setIsIndividualDialogOpen] = React.useState(false);
  const [isUnknownIndividualDialogOpen, setIsUnknownIndividualDialogOpen] = React.useState(false);

  const handleSelectIndividualDialogClose = () => {
    setIsSelectIndividualDialogOpen(false);
  };

  const handleIndividualDialogClose = () => {
    setIsIndividualDialogOpen(false);
  };

  const handleSelectIndividualOnChange = (selected: Individual) => {
    setSelectedIndividuals([...selectedIndividuals, selected]);
    setIsSelectIndividualDialogOpen(false);
  };

  const handleRemoveIndividual = (toRemove: Individual) => {
    setSelectedIndividuals(selectedIndividuals.filter(x => x.id !== toRemove.id));
  };

  const handleUnknownIndividualSave = (value: UnknownIndividual) => {
    setSelectedUnknownIndividuals([...selectedUnknownIndividuals, value]);
    setIsUnknownIndividualDialogOpen(false);
  };

  const handleAddNewUnknownIndividual = () => {
    setSelectedUnknownIndividual(undefined);
    setIsUnknownIndividualDialogOpen(true);
  };

  const handleRemoveUnknownIndividual = (toRemove: UnknownIndividual) => {
    setSelectedUnknownIndividuals(selectedUnknownIndividuals.filter(x => !isEqual(x, toRemove)));
  };

  const selectableIndividual = differenceBy(individuals, selectedIndividuals, "id");;

  return (
    <Container disableGutters sx={{py:2, px:1}}>
      <Stack gap={2}>
        <Paper>
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography variant="h6" component="h2" fontWeight={700}>Individus</Typography>
            <IconButton aria-label="Add" onClick={()=>setIsSelectIndividualDialogOpen(true)}>
              <AddIcon />
            </IconButton>
            <IndividualPickerDialog
              title="Choisir un individu"
              isDialogOpen={isSelectIndividualDialogOpen} 
              handleDialogClose={handleSelectIndividualDialogClose} 
              value={sortBy(selectableIndividual, "firstName")} 
              onChange={handleSelectIndividualOnChange}
            />
          </Stack>
          <Divider />
          <List>
            {selectedIndividuals.length > 0 ? (
              selectedIndividuals.map((individual) => (
                <ListItem key={individual.id} disablePadding secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={()=>handleRemoveIndividual(individual)}>
                    <DeleteIcon />
                  </IconButton>
                }>
                  <ListItemButton disableGutters sx={{p:0, pl:1}} onClick={()=>setIsIndividualDialogOpen(true)}>
                    <ListItemText
                      primary={`${individual.firstName} ${individual.lastName} - ${individual.surname}`}
                      secondary={`${individual.age} ans (${dayjs(individual.birthdate).format("LL")})`}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ):(
              <ListItem>
                <ListItemText primary="Aucun individu" />
              </ListItem>
            )}
          </List>
          <Dialog
            fullScreen
            open={isIndividualDialogOpen}
            onClose={handleIndividualDialogClose}
            TransitionComponent={Transition}
          >
            <IndividualPage handleDialogClose={()=>{setIsIndividualDialogOpen(false);}} />
          </Dialog>
        </Paper>
        <Paper>
          <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{p:1}}>
            <Typography variant="h6" component="h2" fontWeight={700}>Individus inconnus</Typography>
            <IconButton aria-label="Add" onClick={handleAddNewUnknownIndividual}>
              <AddIcon />
            </IconButton>
          </Stack>
          <Divider />
          <List>
            {selectedUnknownIndividuals.length > 0 ? (
              selectedUnknownIndividuals.map((unknownIndividual, index) => (
                <ListItem key={index} disablePadding secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={()=>handleRemoveUnknownIndividual(unknownIndividual)}>
                    <DeleteIcon />
                  </IconButton>
                }>
                  <ListItemButton disableGutters sx={{p:0, pl:1}} onClick={()=>setIsUnknownIndividualDialogOpen(true)}>
                    <ListItemText
                      primary={`Groupe de ${unknownIndividual.count}`}
                      secondary={`${unknownIndividual.gender} (~${unknownIndividual.ageAverage} ans)`}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ):(
              <ListItem>
                <ListItemText primary="Aucun individu inconnu" />
              </ListItem>
            )}
          </List>
          <NewUnknownIndividualDialog 
            isDialogOpen={isUnknownIndividualDialogOpen} 
            unknownIndividual={selectedUnknownIndividual}
            onSave={handleUnknownIndividualSave} 
            handleDialogClose={()=>setIsUnknownIndividualDialogOpen(false)} 
          />
        </Paper>
      </Stack>
    </Container>
  );
};
