import { Box, Button, Container, Dialog, Divider, Drawer, Grid, Paper, Slide, Stack, TextField, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { EntityPageLayout } from "components/Layout";
import React, { ChangeEvent, useEffect } from "react";
import { DistributionItem, DistributionValue } from "types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { SelectDistributionItemInput } from "components/SelectDistributionItem";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface AddDistributionDialogProps {
  isDialogOpen: boolean,
  distribution?: DistributionValue,
  handleDialogClose: ()=>void,
  onSave: (value: DistributionValue)=>void,
}

export const AddDistributionDialog: React.FC<AddDistributionDialogProps> = (props: AddDistributionDialogProps) => {

  const [distributionValue, setDistributionValue] = React.useState<DistributionValue>(() => {
    return props.distribution ? props.distribution : {
      id: 0,
      name: "",
      unit: "",
      quantity: 0
    };
  });

  useEffect(() => {
    if(props.distribution) {
      setDistributionValue(props.distribution);
    }else
    {
      setDistributionValue({
        id: 0,
        name: "",
        unit: "",
        quantity: 0
      });
    }
  },[props.distribution]);

  const handleOnSave = () => {
    props.onSave(distributionValue);
  };

  const handleDistributionItemSelect = (selected: DistributionItem) =>{
    setDistributionValue({...distributionValue,
      id: selected.id,
      name: selected.name,
      unit: selected.unit
    });
  };

  const handleOnQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDistributionValue({...distributionValue, quantity: event.target.valueAsNumber});
  };

  return (
    <Dialog
      fullScreen
      open={props.isDialogOpen}
      onClose={props.handleDialogClose}
      TransitionComponent={Transition}
    >
      <EntityPageLayout
        leftIcon={<ArrowBackIcon />}
        leftIconOnClick={props.handleDialogClose}
        rightIcon={<SaveIcon />}
        rightIconOnClick={handleOnSave}
        title="Ajouter une distribution"
      >
        <Container disableGutters sx={{py:2, px:1}}>
          <Paper>
            <Box sx={{p:1}}>
              <Typography variant="h6" component="h2" fontWeight={700}>Distribution</Typography>
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{p:1}}>
              <Grid item xs={12} md={6}>
                <SelectDistributionItemInput 
                  label="Item"
                  selectedItem={props.distribution ? {
                    id: props.distribution.id,
                    name: props.distribution.name,
                    unit: props.distribution.unit
                  }: undefined}
                  onChange={handleDistributionItemSelect} />
              </Grid>
              {distributionValue.unit !== "" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    type="number"
                    label={distributionValue.unit}
                    value={distributionValue.quantity}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    variant="standard"
                    fullWidth
                    onChange={handleOnQuantityChange}
                    onFocus={event => {
                      event.target.select();
                    }}
                  />
                </Grid>
              )}          
            </Grid>
          </Paper>
        </Container>
        <Drawer anchor='bottom' open={true} variant="persistent" elevation={16}>
          <Stack direction="row" justifyContent="flex-end" sx={{p:1}}>
            <Box>
              <Button variant="contained" color="success" startIcon={<SaveIcon />} onClick={handleOnSave}>Sauvegarder</Button>
            </Box>
          </Stack>
        </Drawer>
      </EntityPageLayout>
    </Dialog>
  );
};