import { createTheme, PaletteMode, Theme } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
      appBar: Partial<TypeBackground>;
  }
  interface PaletteOptions {
      appBar: Partial<TypeBackground>;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorPartial {}
}

const getPaletteTheme = (mode: PaletteMode) => {
  return createTheme(
    {
      palette: {
        mode,
        ...(mode === "light"
          ? {
            primary: {
              main: "#40A1AB",
              light: "#76D2DD",
              dark: "#00727C",
              contrastText: "#fff"
            },
            secondary: {
              main: "#F6D50E",
              light: "#FFFF55",
              dark: "#BFA400",
              contrastText: "#000"
            },
            warning: {
              main: "#ED6C02",
              "50": "#fff3e0"        
            },
            error: {
              main: "#F6380E",   
            },
            background: {
              default: grey[50]
            },
            appBar: {
              default: "#fff"
            },
            grey: {
              ...blueGrey
            }
          }
          : {
            primary: {
              main: "#40A1AB",
              light: "#76D2DD",
              dark: "#00727C",
              contrastText: "#fff"
            },
            secondary: {
              main: "#F6D50E",
              light: "#FFFF55",
              dark: "#BFA400",
              contrastText: "#000"
            },
            background: {
              default: blueGrey[900],
              paper: blueGrey[700],            
            },
            appBar: {
              default: blueGrey[800]
            },
          }),
      },
    }
  );
};

export const createAppTheme = (mode: PaletteMode): Theme => {
  const paletteTheme = getPaletteTheme(mode);

  return createTheme(
    {
      ...paletteTheme,
      components: {
        MuiAppBar: {
          styleOverrides: {
            colorDefault: {
              color: paletteTheme.palette.primary.main,
              backgroundColor: paletteTheme.palette.appBar.default
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              "&.MuiDialog-paper":{
                backgroundColor: paletteTheme.palette.background.default
              },
              "&.MuiMobileStepper-root": {
                backgroundColor: paletteTheme.palette.background.paper
              }
            }
          }
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              paddingLeft: paletteTheme.spacing(2),
              paddingRight: paletteTheme.spacing(2)
            }
          }
        }
      },
    });
};
