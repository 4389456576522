import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as CustomIcon } from "./other-gender.svg";

export const OthergenderIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <CustomIcon />
    </SvgIcon>
  );
};
