import React from "react";
import { Box } from "@mui/system";
import { TopBar } from "./TopBar";

interface PageLayoutProps {
    children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }: PageLayoutProps) => {

  return (
    <Box sx={{display:"flex", flexDirection:"column"}}>
      <TopBar />
      {children}
    </Box>
  );
};
