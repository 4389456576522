import React from "react";
import { Dialog, List, DialogTitle, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { GenderItemValue } from "./types";

export interface SelectGenderDialogProps {
  isDialogOpen: boolean,
  handleDialogClose: ()=>void,
  value: GenderItemValue[],
  onChange: (selected: GenderItemValue)=>void,
}

export const SelectGenderDialog: React.FC<SelectGenderDialogProps> = (props: SelectGenderDialogProps) => {

  const handleOnClick = (item:GenderItemValue) => {
    props.onChange(item);
  };

  return (
    <Dialog onClose={props.handleDialogClose} open={props.isDialogOpen}>
      <DialogTitle>Sélectionner le genre</DialogTitle>
      <List sx={{ pt: 0 }}>
        {props.value.map((gender) => (
          <ListItem button onClick={() => handleOnClick(gender)} key={gender.id}>
            <ListItemIcon>
              {gender.icon}
            </ListItemIcon>
            <ListItemText primary={gender.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};