import React from "react";
import { Dialog, Slide, List, Paper, Stack, InputAdornment, styled, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransitionProps } from "@mui/material/transitions";
import { EntityPageLayout } from "components/Layout";
import { SelectDistributionListItem } from "./SelectDistributionListItem";
import { DistributionItem } from "types";
import deburr from "lodash/deburr";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SearchTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    }
  },
});

export interface SelectDistributionItemDialogProps {
  title: string,
  isDialogOpen: boolean,
  handleDialogClose: ()=>void,
  value: DistributionItem[],
  onChange: (selected: DistributionItem)=>void,
}

export const SelectDistributionItemDialog: React.FC<SelectDistributionItemDialogProps> = (props: SelectDistributionItemDialogProps) => {

  const [textSearch, setTextSearch] = React.useState("");

  const handleSearchOnChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(event.target.value);
  };

  const handleOnClick = (item:DistributionItem) => {
    props.onChange(item);
  };

  const filteredValue = props.value.filter(
    (result) => deburr(Object.values([result.name,result.unit]).toString())
      .toLowerCase()
      .indexOf(deburr(textSearch).toLowerCase()) > -1
  );

  return (
    <Dialog
      fullScreen
      open={props.isDialogOpen}
      onClose={props.handleDialogClose}
      TransitionComponent={Transition}
    >
      <EntityPageLayout
        leftIcon={<ArrowBackIcon />}
        leftIconOnClick={props.handleDialogClose}
        title={props.title}
      >
        <Stack sx={{backgroundColor: "background.paper", mb:1}}>
          <SearchTextField
            fullWidth 
            placeholder="Rechercher..." 
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchOnChange}
          />
        </Stack>
        <Paper>
          <List>
            {filteredValue.map((item)=>(
              <SelectDistributionListItem key={item.id} item={item} onClick={()=>handleOnClick(item)} />
            ))}
          </List>
        </Paper>
      </EntityPageLayout>
    </Dialog>
  );
};