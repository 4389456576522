import { DistributionItemCategory } from "types";

export const DistributionItemCategories : DistributionItemCategory[] = [
  {
    id: 1,
    name: "Drogues",
    imageSrc: "https://media.gettyimages.com/photos/drugs-and-needle-picture-id160636317?s=2048x2048",
    items: [
      {
        id: 1,
        name: "Bandelette test",
        unit: "Unités"
      },
      {
        id: 2,
        name: "Kit - Tube pyres",
        unit: "Unités"
      },
      {
        id: 3,
        name: "Naloxone Injectable",
        unit: "Unités"
      },
      {
        id: 4,
        name: "Naloxone Nasal",
        unit: "Unités"
      },
      {
        id: 5,
        name: "Pipe à cristal",
        unit: "Unités"
      },
      {
        id: 6,
        name: "Seringues - Kit de 10",
        unit: "Nombre de kit de 10"
      },
      {
        id: 7,
        name: "Seringues - Kit de 4",
        unit: "Nombre de kit de 4"
      },
      {
        id: 8,
        name: "Seringues - Kit noir 3cc",
        unit: "Unités"
      },
      {
        id: 9,
        name: "Seringues",
        unit: "Unités"
      },
      {
        id: 10,
        name: "Test de dépistage",
        unit: "Unités"
      },
    ]
  },
  {
    id: 2,
    name: "Sex",
    imageSrc: "https://media.istockphoto.com/photos/close-up-of-colored-condoms-on-white-background-picture-id1167827230",
    items: [
      {
        id: 11,
        name: "Condoms",
        unit: "Nombre de distributions"
      },
      {
        id: 12,
        name: "Lubrifiants",
        unit: "Nombre de distributions"
      },
    ]
  },
  {
    id: 3,
    name: "Contenants",
    imageSrc: "http://mcas-systems.eu/pub/media/catalog/product/cache//433x433/beff4985b56e3afdbeabfc89641a4582/p/l/plastic-canister-polyethylene-30-litre.jpg",
    items: [
      {
        id: 13,
        name: "Contenant 250 ml noir",
        unit: "Unités"
      },
      {
        id: 14,
        name: "Contenant 1.4 litre",
        unit: "Unités"
      },
      {
        id: 15,
        name: "Contenant 5 litre",
        unit: "Unités"
      },
    ]
  }
];