import React from "react";
import { AppBar, Avatar, IconButton, Stack, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const TopBar: React.FC = () => {

  return (
    <>
      <AppBar color="default">
        <Toolbar>
          <Stack direction='row' justifyContent="flex-start" alignItems="center" sx={{ flexGrow: 1 }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton>
          </Stack>
          <Stack direction='row' spacing={2}>
            <Stack direction='row' spacing={1}>
              <IconButton>
                <NotificationsIcon />
              </IconButton>
              <IconButton>
                <ZoomOutMapIcon />
              </IconButton>
            </Stack>
            <Avatar>H</Avatar>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
