import React from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { DistributionItem } from "types";

export interface SelectDistributionListItemProps {
  item: DistributionItem,
  onClick: ()=>void
}

export const SelectDistributionListItem: React.FC<SelectDistributionListItemProps> = (props: SelectDistributionListItemProps) => {
  
  return (
    <ListItem disablePadding >
      <ListItemButton onClick={props.onClick}>        
        <ListItemText 
          primary={props.item.name} 
          secondary={props.item.unit} />
      </ListItemButton>
    </ListItem>
  );
};