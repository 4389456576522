import React, { useRef } from "react";
import { Backdrop, Button, CircularProgress, Drawer, MobileStepper, Stack, Toolbar, Typography } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useSnackbar } from "notistack";
import { NewInterventionSection1 } from "./NewInterventionSection1";
import { NewInterventionSection2 } from "./NewInterventionSection2";
import { NewInterventionSection3 } from "./NewInterventionSection3";
import { NewInterventionSection4 } from "./NewInterventionSection4";
import { NewInterventionChooseType } from "./NewInterventionChooseType";

export interface NewInterventionPageProps {
  onSave: ()=>void
  dialogRef: React.RefObject<HTMLDivElement>
}

export const NewInterventionPage: React.FC<NewInterventionPageProps> = (props: NewInterventionPageProps) => {

  const [activeStep, setActiveStep] = React.useState(0);
  const [isSaving, setIsSaving] = React.useState(false);
  const [interventionType, setInterventionType] = React.useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnInterventionTypeChange = (value: string) => {
    setInterventionType(value);
  };

  const handleNext = () => {
    if(activeStep === 3) {
      setIsSaving(true);
      setTimeout(() => {
        setIsSaving(false);
        enqueueSnackbar("Enregistrement réussi", { 
          variant: "success",
        });
        props.onSave();
      }, 2000);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    scrollTop();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollTop();
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
    scrollTop();
  };

  const scrollTop = () => {
    if(props.dialogRef && props.dialogRef.current){
      props.dialogRef.current.scrollTop = 0;
    }
  };

  return (
    <>
      {interventionType === undefined ? (
        <NewInterventionChooseType onChange={handleOnInterventionTypeChange} />
      ) : (
        <>
          <SwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            onSwitching={scrollTop}
          >
            <NewInterventionSection1 />
            <NewInterventionSection2 />
            <NewInterventionSection3 />
            <NewInterventionSection4 />
          </SwipeableViews>
          <Toolbar />
          <Drawer anchor='bottom' open={true} variant="persistent" elevation={16}>
            <MobileStepper
              steps={4}
              variant="progress"
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  onClick={handleNext}
                  sx={{ml:2}}
                >
                  {activeStep === 3 ? "Terminer" : "Suivant"}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button onClick={handleBack} disabled={activeStep === 0} sx={{mr:2}}>
                  <KeyboardArrowLeft />
              Précédent
                </Button>
              }
            />
          </Drawer>
          <Backdrop
            sx={{ color: "common.white", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSaving}
          >
            <Stack direction='column' alignItems='center'>
              <CircularProgress color="inherit" />
              <Typography variant='body1' sx={{mt:2}}>Enregistrement en cours</Typography>
            </Stack>
          </Backdrop> 
        </>
      )}
    </>
  );
};