import React from "react";
import { ListItem, ListItemButton, Stack, InputLabel, FormControl, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DistributionItem } from "types";
import { SelectDistributionCategoryDialog } from "./SelectDistributionCategoryDialog";

export interface SelectDistributionItemInputProps {
  label: string,
  selectedItem?: DistributionItem,
  onChange: (selected: DistributionItem) => void
}

export const SelectDistributionItemInput: React.FC<SelectDistributionItemInputProps> = (props: SelectDistributionItemInputProps) => {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<DistributionItem|undefined>(props.selectedItem);

  const handleClickOpenButton = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOnSelected = (selection: DistributionItem) => {
    setSelected(selection);
    props.onChange(selection);
  };

  return (
    <>
      <FormControl fullWidth sx={{borderBottomColor: "text.disabled", borderBottomStyle:"solid", borderBottomWidth: 1}} required>
        <InputLabel variant='standard' shrink>{props.label}</InputLabel>
        <ListItem disablePadding sx={{mt:2}}>
          <ListItemButton disableGutters sx={{py:1/2}} onClick={handleClickOpenButton}>
            <Stack direction='row' justifyContent="space-between" sx={{flexGrow:1}}>
              <Stack direction='row' gap={1/2} flexWrap="wrap">
                {selected == null ? (
                  <Typography>Sélectionner...</Typography>
                ) : (
                  <Typography>{selected?.name}</Typography>
                )}
              </Stack>
            </Stack>
            <ChevronRightIcon />
          </ListItemButton>
        </ListItem>
      </FormControl>
      <SelectDistributionCategoryDialog
        isDialogOpen={isDialogOpen} 
        handleDialogClose={handleDialogClose} 
        onChange={handleOnSelected}
      />
    </>
  );
};