import React from "react";
import { Container, Dialog, Grid, Slide, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransitionProps } from "@mui/material/transitions";
import { EntityPageLayout } from "components/Layout";
import { DistributionItem } from "types";
import { SelectDistributionCard } from "./SelectDistributionCard";
import { SelectDistributionItemDialog } from "./SelectDistributionItemDialog";
import { DistributionItemCategories } from "modules/interventions/DistributionItems";
import sortBy from "lodash/sortBy";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface SelectDistributionCategoryDialogProps {
  isDialogOpen: boolean,
  handleDialogClose: ()=>void,
  onChange: (selected: DistributionItem)=>void,
}

export const SelectDistributionCategoryDialog: React.FC<SelectDistributionCategoryDialogProps> = (props: SelectDistributionCategoryDialogProps) => {

  const [isSelectDistributionItemDialogOpen, setIsSelectDistributionItemDialogOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(0);

  const handleCategorySelected = (catergoryId: number) => {
    setSelectedCategory(catergoryId);
    setIsSelectDistributionItemDialogOpen(true);
  };

  const handleSelectDistributionItemDialogClode = () => {
    setIsSelectDistributionItemDialogOpen(false);
  };

  const handleOnChange = (selected: DistributionItem) => {
    props.onChange(selected);
    props.handleDialogClose();
  };

  const allDistributionItem = DistributionItemCategories.reduce<DistributionItem[]>((previousValue, currentValue) => {
    return previousValue.concat(currentValue.items);
  }, []);

  const filteredItems = selectedCategory === 0 ? allDistributionItem : DistributionItemCategories[selectedCategory -1].items;

  return (
    <Dialog
      fullScreen
      open={props.isDialogOpen}
      onClose={props.handleDialogClose}
      TransitionComponent={Transition}
    >
      <EntityPageLayout
        leftIcon={<ArrowBackIcon />}
        leftIconOnClick={props.handleDialogClose}
        title={"Selectionner une categorie"}
      >
        <Container sx={{py:2}}>
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography variant="h6" component="h1" fontWeight={700}>Catégories</Typography>
          </Stack>
          <Grid container spacing={1} sx={{mt:1}}>
            <SelectDistributionCard label="Tous" onClick={() => handleCategorySelected(0)}/>
            {DistributionItemCategories.map((categorie) => (
              <SelectDistributionCard key={categorie.id} label={categorie.name} imageSrc={categorie.imageSrc} onClick={() => handleCategorySelected(categorie.id)}/>
            ))}          
          </Grid>
        </Container>
      </EntityPageLayout>
      <SelectDistributionItemDialog
        title="Choisir un item"
        isDialogOpen={isSelectDistributionItemDialogOpen} 
        handleDialogClose={handleSelectDistributionItemDialogClode} 
        value={sortBy(filteredItems, "name")} 
        onChange={handleOnChange}
      />
    </Dialog>
  );
};