import React from "react";
import { ListItem, ListItemButton, Stack, InputLabel, FormControl, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import sortBy from "lodash/sortBy";
import { GenderItemValue } from "./types";
import { SelectGenderDialog } from "./SelectGenderDialog";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import TransgenderIcon from "@mui/icons-material/Transgender";
import { OthergenderIcon } from "./icons/OthergenderIcon";

const genders: GenderItemValue[] = [
  { id: 1, name: "Homme", icon: <MaleIcon />},
  { id: 2, name: "Femme", icon: <FemaleIcon/>},
  { id: 3, name: "Transgenre", icon: <TransgenderIcon /> },
  { id: 4, name: "Autre", icon: <OthergenderIcon />},
];

export interface SelectGenderInputProps {
  label: string,
  onChange: (selected: GenderItemValue) => void
}

export const SelectGenderInput: React.FC<SelectGenderInputProps> = (props: SelectGenderInputProps) => {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<GenderItemValue>();

  const handleClickOpenButton = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOnSelected = (selection: GenderItemValue) => {
    setSelected(selection);
    props.onChange(selection);
    setIsDialogOpen(false);
  };

  return (
    <>
      <FormControl fullWidth sx={{borderBottomColor: "text.disabled", borderBottomStyle:"solid", borderBottomWidth: 1}}>
        <InputLabel variant='standard' shrink>{props.label}</InputLabel>
        <ListItem disablePadding sx={{mt:2}}>
          <ListItemButton disableGutters sx={{py:1/2}} onClick={handleClickOpenButton}>
            <Stack direction='row' justifyContent="space-between" sx={{flexGrow:1}}>
              <Stack direction='row' gap={1/2} flexWrap="wrap">
                {selected == null ? (
                  <Typography>Sélectionner...</Typography>
                ) : (
                  <Typography>{selected?.name}</Typography>
                )}
              </Stack>
            </Stack>
            <ChevronRightIcon />
          </ListItemButton>
        </ListItem>
      </FormControl>
      <SelectGenderDialog
        isDialogOpen={isDialogOpen} 
        handleDialogClose={handleDialogClose} 
        value={sortBy(genders, ["id"])} 
        onChange={handleOnSelected}
      />
    </>
  );
};