import React, { ChangeEvent } from "react";
import { Dialog, Slide, Paper, Typography, Divider, Container, Box, Grid, TextField, Drawer, Stack, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransitionProps } from "@mui/material/transitions";
import { EntityPageLayout } from "components/Layout";
import { UnknownIndividual } from "types";
import SaveIcon from "@mui/icons-material/Save";
import { SelectGenderInput } from "components/SelectGender/SelectGenderInput";
import { GenderItemValue } from "components/SelectGender/types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface NewUnknownIndividualDialogProps {
  isDialogOpen: boolean,
  unknownIndividual?: UnknownIndividual,
  handleDialogClose: ()=>void,
  onSave: (value: UnknownIndividual)=>void,
}

export const NewUnknownIndividualDialog: React.FC<NewUnknownIndividualDialogProps> = (props: NewUnknownIndividualDialogProps) => {

  const [individual, setIndividual] = React.useState<UnknownIndividual>({
    count: 0,
    ageAverage: 0,
    gender: "" 
  });

  const handleOnSave = () => {
    props.onSave(individual);
    setIndividual({
      count: 0,
      ageAverage: 0,
      gender: "" 
    });
  };

  const handleGenderSelect = (selected: GenderItemValue) =>{
    setIndividual({...individual, gender: selected.name});
  };

  const handleOnCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIndividual({...individual, count: event.target.valueAsNumber});
  };

  const handleOnAgeAverageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIndividual({...individual, ageAverage: event.target.valueAsNumber});
  };

  return (
    <Dialog
      fullScreen
      open={props.isDialogOpen}
      onClose={props.handleDialogClose}
      TransitionComponent={Transition}
    >
      <EntityPageLayout
        leftIcon={<ArrowBackIcon />}
        leftIconOnClick={props.handleDialogClose}
        rightIcon={<SaveIcon />}
        rightIconOnClick={handleOnSave}
        title="Individus inconnus"
      >
        <Container disableGutters sx={{py:2, px:1}}>
          <Paper>
            <Box sx={{p:1}}>
              <Typography variant="h6" component="h2" fontWeight={700}>Individus</Typography>
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{p:1}}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  label="Nombre d'usagers"
                  defaultValue="0"
                  value={individual.count}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  variant="standard"
                  fullWidth
                  onChange={handleOnCountChange}
                  onFocus={event => {
                    event.target.select();
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectGenderInput 
                  label="Genre"
                  onChange={handleGenderSelect} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  label="Moyenne d'âge"
                  defaultValue="0"
                  value={individual.ageAverage}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  variant="standard"
                  fullWidth
                  onChange={handleOnAgeAverageChange}
                  onFocus={event => {
                    event.target.select();
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <Drawer anchor='bottom' open={true} variant="persistent" elevation={16}>
          <Stack direction="row" justifyContent="flex-end" sx={{p:1}}>
            <Box>
              <Button variant="contained" color="success" startIcon={<SaveIcon />} onClick={handleOnSave}>Sauvegarder</Button>
            </Box>
          </Stack>
        </Drawer>
      </EntityPageLayout>
    </Dialog>
  );
};