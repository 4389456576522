import React from "react";
import { Box, Button, Container, Drawer, RadioGroup, Stack, Toolbar, Typography } from "@mui/material";
import { InterventionTypeCard } from "modules/interventions/components/InterventionTypeCard";

export interface NewInterventionChooseTypeProps {
  onChange: (value: string)=>void
}

export const NewInterventionChooseType: React.FC<NewInterventionChooseTypeProps> = (props: NewInterventionChooseTypeProps) => {

  const [interventionType, setInterventionType] = React.useState<string>("regulier");

  const handleOnClickContinu = () => {
    props.onChange(interventionType);
  };

  const handleInterventionCardClick = (value: string) => {
    setInterventionType(value);
  };

  return (
    <Container disableGutters sx={{py:2, px:1}}>
      <Typography variant="h6">Sélectionner le type de l’intervention pour commencer</Typography>
      <RadioGroup defaultValue="regulier" sx={{mt:2}}>
        <Stack gap={1}>
          <InterventionTypeCard 
            value="regulier"
            title="Régulier"
            description="Un intervention régulier auprès d’un ou plusieurs individus."
            isSelected={interventionType === "regulier"}
            onClick={handleInterventionCardClick}
          />
          <InterventionTypeCard 
            value="routine"
            title="Routine"
            description="Un intervention récurent auprès d’un ou plusieurs individus."
            isSelected={interventionType === "routine"}
            onClick={handleInterventionCardClick}
          />
          <InterventionTypeCard 
            value="presentation"
            title="Présentation"
            description="Un intervention récurent auprès d’un ou plusieurs individus sans distribution."
            isSelected={interventionType === "presentation"}
            onClick={handleInterventionCardClick}
          />
          <InterventionTypeCard 
            value="distribution"
            title="Distribution"
            description="Une simple distribution."
            isSelected={interventionType === "distribution"}
            onClick={handleInterventionCardClick}
          />
        </Stack>
      </RadioGroup>
      <Toolbar />
      <Drawer anchor='bottom' open={true} variant="persistent" elevation={16}>
        <Stack direction="row" justifyContent="flex-end" sx={{p:1}}>
          <Box>
            <Button variant="contained" color="success" onClick={handleOnClickContinu}>Continuer</Button>
          </Box>
        </Stack>
      </Drawer>
    </Container>
  );
};