import { Checkbox, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { RealitySpecification } from "types";



export interface SpecificationListItemProps {
  specification: RealitySpecification,
  isChecked: boolean,
  onChange: (checked: boolean, item: RealitySpecification) => void;
}

export const SpecificationListItem: React.FC<SpecificationListItemProps> = (props: SpecificationListItemProps) => {

  const handleOnClick = () => {
    props.onChange(!props.isChecked, props.specification);
  };

  return (
    <ListItem
      secondaryAction={
        <Checkbox
          edge="end"
          checked={props.isChecked}
          onClick={handleOnClick}
        />
      }
      disablePadding
    >
      <ListItemButton sx={{pl:4}} onClick={handleOnClick}>
        <ListItemText primary={props.specification.name} />
      </ListItemButton>
    </ListItem>
  );
};